import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import { GanttChartTypeOfAttachmentEnum } from "@types";

import { axiosService } from "../../../../services";
import { FormContent } from "./FormContent";

export const AddFileToGanttChart = ({
  open,
  onClose,
  onAddFile,
}: {
  open: boolean;
  onClose: () => void;
  onAddFile: (
    file: File[],
    typeOfAttachment: GanttChartTypeOfAttachmentEnum,
    fileId: number
  ) => void;
}) => {
  const { t } = useTranslation("attachments");

  const [selectedFile, setSelectedFile] = React.useState<File[] | null>(null);
  const [selectedFileId, setSelectedFileId] = React.useState<number | null>(
    null
  );
  const [typeOfAttachment, setTypeOfAttachment] = React.useState<
    GanttChartTypeOfAttachmentEnum | undefined
  >(undefined);

  const handleSelectFile = async (
    file: File[],
    typeOfAttachment: GanttChartTypeOfAttachmentEnum
  ) => {
    const { data } = await axiosService({
      endpoint: "/files",
      method: "PUT",
      body: { file: file[0] },
      headers: { "Content-Type": "multipart/form-data" },
    });

    setSelectedFile(file);
    setSelectedFileId(data?.id);
    setTypeOfAttachment(typeOfAttachment);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setSelectedFileId(null);
    setTypeOfAttachment(undefined);
  };

  const content = {
    body: (
      <FormContent
        file={selectedFile}
        onClose={onClose}
        onAddFile={handleSelectFile}
        onRemoveFile={handleRemoveFile}
      />
    ),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        disabled={!selectedFile || !selectedFile.length || !typeOfAttachment}
        onClick={() => {
          onClose();
          onAddFile(selectedFile!, typeOfAttachment!, selectedFileId!);
        }}
      >
        {t("add")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
