import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const CorrespondentWrapper = styled.div<{
  $isMessageViewed: boolean;
  $isIncomingMessage: boolean;
  $fromSystem?: boolean;
}>`
  font-weight: ${({ $isMessageViewed, $isIncomingMessage }) =>
    $isMessageViewed ? 500 : $isIncomingMessage ? 700 : 500};
  ${FLEX_ROW_FULL};
  overflow: visible !important;
  gap: ${({ theme }) => theme.spacing(2)};
  .correspondent__avatar {
    ${FLEX_ROW_FULL};
    width: 40px;
    height: 40px;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ActivityIndicator = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.success.main};
  margin: 28px 0 0 32px;
`;
