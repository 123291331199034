import { TFunction } from "i18next";

import { Palette } from "@mui/material";
import {
  StatusForExecutorEnum,
  StatusForAuthorEnum,
  StatusForCorrespondenceEnum,
} from "@types";

const COMPLETED_TYPES = [
  StatusForExecutorEnum.done,
  StatusForExecutorEnum.doneInPerson,
  StatusForExecutorEnum.canceled,
  StatusForExecutorEnum.executionCompleted,
  StatusForExecutorEnum.executedWithoutResolution,

  StatusForAuthorEnum.done,
  StatusForAuthorEnum.canceled,
];

const IN_THE_SPOTLIGHT_TYPES = [
  StatusForExecutorEnum.delegated,
  StatusForExecutorEnum.rejected,
  StatusForExecutorEnum.executionInterrupted,
  StatusForAuthorEnum.sentToExecutor,
  StatusForExecutorEnum.acceptedForWork,
  StatusForAuthorEnum.sentToSupport,
  StatusForExecutorEnum.resolutionIssued,
];

export const getTaskStatusChipConfig = (
  status:
    | StatusForExecutorEnum
    | StatusForAuthorEnum
    | StatusForCorrespondenceEnum
    | undefined,
  palette: Palette,
  t: TFunction,
  isTranslated = true,
  isAuthor = false
) => {
  if (!status)
    return {
      backgroundColor: palette.secondary.main,
      color: palette.primary.contrastText,
    };

  const completedTypesArray = isTranslated
    ? COMPLETED_TYPES.map((i) => t(i))
    : COMPLETED_TYPES;

  const inTheSpotlightTypes = isTranslated
    ? IN_THE_SPOTLIGHT_TYPES.map((i) => t(i))
    : IN_THE_SPOTLIGHT_TYPES;

  /**
   * For the Author statuses the REJECTED should be moved from Spotlight category to Completed
   */
  if (isAuthor) {
    const valueToRemove = isTranslated
      ? t(StatusForExecutorEnum.rejected)
      : StatusForExecutorEnum.rejected;
    const indexOfRemoved = inTheSpotlightTypes.indexOf(valueToRemove);

    if (indexOfRemoved !== -1) {
      inTheSpotlightTypes.splice(indexOfRemoved, 1);
    }
  } else {
    if (!inTheSpotlightTypes.includes(StatusForExecutorEnum.rejected)) {
      inTheSpotlightTypes.push(StatusForExecutorEnum.rejected);
    }
  }

  switch (true) {
    // Completed
    case completedTypesArray.includes(status):
      return {
        backgroundColor: palette.other.grey,
        color: palette.text.secondary,
      };
    // Required Action
    case inTheSpotlightTypes.includes(status):
      return {
        backgroundColor: "#6173A0",
        color: palette.primary.contrastText,
      };
    // In the spotlight
    default:
      return {
        backgroundColor: palette.secondary.main,
        color: palette.primary.contrastText,
      };
  }
};
