import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
  .MuiDataGrid-detailPanelToggleCell {
    height: 30px;
    width: 30px;
  }
`;

export const DocumentViewingIndicatorWrapper = styled.div<{
  $isViewed: boolean;
}>`
  ${FLEX_ROW_FULL};
  gap: ${({ theme }) => theme.spacing()};
  overflow: visible !important;
  .read_unread_icon {
    opacity: ${({ $isViewed }) => ($isViewed ? 0 : 1)};
    background-color: ${({ $isViewed, theme }) =>
      $isViewed ? "" : theme.palette.primary.dark};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      opacity: 1;
      background-color: ${({ $isViewed, theme }) =>
        $isViewed ? theme.palette.primary.light : theme.palette.primary.dark};
    }
  }
`;
