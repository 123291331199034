import React, { useRef } from "react";

import { useAppDispatch } from "app";
import { setLoadedFilesCount } from "app";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { hexToRgba } from "@sbm/fe-utils";

import { formatSize, getRandomValue, createObjectURL } from "./helpers";

interface Props {
  file: File;
  removeFile: () => void;
}

export const FileItem: React.FC<Props> = ({ file, removeFile }) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const uploadTimer = useRef<{ value: number | NodeJS.Timer }>({ value: 0 });

  const isFileUploaded = Number(uploadProgress) >= 100;

  React.useEffect(() => {
    const randomValue = getRandomValue(10, 25);
    const currentUploadTimer = uploadTimer.current;

    if (file !== null) {
      setUploadProgress(0);

      const timerId = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timerId);
            return 100;
          }

          return prevProgress + randomValue;
        });
      }, 300);

      currentUploadTimer.value = timerId;
    }

    return () => {
      clearInterval(currentUploadTimer.value);
    };
  }, [file]);

  React.useEffect(() => {
    if (!isFileUploaded) return;
    dispatch(setLoadedFilesCount(1));
    return () => {
      dispatch(setLoadedFilesCount(-1));
    };
  }, [isFileUploaded, dispatch]);

  if (!file) return null;

  return (
    <>
      <div className="dropzone__uploadingProcess">
        <div style={{ display: "flex", gap: "4px" }}>
          <a
            href={createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            className="uploaded_file"
          >
            <Typography variant="body2" fontWeight={600} color="primary.main">
              {`${file.name}`}
            </Typography>
          </a>
          <Typography
            variant="body2"
            fontWeight={600}
            color={hexToRgba(theme.palette.primary.main, 0.45)}
          >
            {`(${formatSize(file.size)})`}
          </Typography>
        </div>

        <div className="dropzone__liner">
          <IconButton onClick={removeFile}>
            <ClearIcon fontSize="small" fontWeight="600" color="primary" />
          </IconButton>
        </div>
      </div>
      {!isFileUploaded && (
        <LinearProgress
          variant="determinate"
          value={uploadProgress || 0}
          valueBuffer={uploadProgress + 10}
          color="secondary"
          style={{ background: hexToRgba(theme.palette.text.primary, 0.04) }}
        />
      )}
    </>
  );
};
