import React from "react";
import { useTranslation } from "react-i18next";

import { useGetWidgetsForHomepage } from "api";
import { Icon, Widget, WidgetWithChart } from "ui-kit";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ChapterEnum, TIconNames } from "@types";

import {
  getChart,
  getDescription,
  getHref,
  getMaintanenceMode,
  getIconName,
} from "./helpers";
import { HomePageInstrumentsWrapper } from "./styles";

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon name="ChevronRight" className="cursor-pointer" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "none",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

export const HomePageInstruments: React.FC = () => {
  const { palette, shadows } = useTheme();
  const { t } = useTranslation("tasks");

  const { data: widgets, isLoading } = useGetWidgetsForHomepage();

  const toolsWidgets = React.useMemo(() => {
    if (!widgets) return [];

    return widgets
      .filter((w) => w.chapter === ChapterEnum.tool)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      )
      .map((w) => {
        const serviceId = w.widgetServiceId!;

        return {
          title: w.widgetName,
          maintenanceMode: getMaintanenceMode(serviceId),
          icon: getIconName(serviceId),
          chart: getChart(serviceId),
          href: getHref(serviceId, w.widgetOrganizationId),
          description: getDescription(serviceId),
        } as {
          title: string;
          maintenanceMode: boolean | undefined;
          icon: string | undefined;
          href: string | undefined;
          description: JSX.Element | null;
          chart: JSX.Element | null;
        };
      });
  }, [widgets]);

  const projectWidgets = React.useMemo(() => {
    if (!widgets) return [];

    return widgets
      .filter((w) => w.chapter === ChapterEnum.project)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      );
  }, [widgets]);

  if (isLoading || !widgets?.length) return null;

  return (
    <>
      <Typography fontSize={28} fontWeight={700} sx={{ mt: 6 }}>
        {t("tasks_card_view.favorites")}
      </Typography>

      <HomePageInstrumentsWrapper>
        {toolsWidgets?.length > 0 && (
          <Accordion
            disableGutters
            TransitionProps={{
              timeout: { appear: 1, enter: 1, exit: 4 },
            }}
            sx={{
              boxShadow: shadows[4],
              borderRadius: "16px !important",
              background: palette.other.grey,
            }}
          >
            <AccordionSummary
              aria-controls="homepage-tools_widget-content"
              id="homepage-tools_widget-header"
              sx={{ px: 6 }}
              expandIcon={
                <Icon
                  name="ChevronRight"
                  color={palette.primary.main}
                  className="cursor-pointer"
                />
              }
            >
              <Typography variant="h7" fontWeight={700} sx={{ py: 3 }}>
                ИНСТРУМЕНТЫ
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ px: 6, pb: 6 }}>
              <Grid container mt={1} spacing={6}>
                {toolsWidgets.map((module) => {
                  const {
                    title,
                    icon,
                    href,
                    chart,
                    maintenanceMode,
                    description,
                  } = module;

                  return (
                    <Grid
                      key={title}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      xl={4}
                      sx={{ minHeight: 423 }}
                    >
                      <WidgetWithChart
                        title={title}
                        icon={icon as TIconNames | null}
                        href={href}
                        maintenanceMode={maintenanceMode}
                        chart={chart}
                        description={description}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {projectWidgets?.length > 0 && (
          <Accordion
            disableGutters
            TransitionProps={{
              timeout: { appear: 1, enter: 1, exit: 4 },
            }}
            sx={{
              boxShadow: shadows[4],
              borderRadius: "16px !important",
              background: palette.other.grey,
            }}
          >
            <AccordionSummary
              aria-controls="homepage-tools_widget-content"
              id="homepage-tools_widget-header"
              sx={{ px: 6 }}
              expandIcon={
                <Icon
                  name="ChevronRight"
                  color={palette.primary.main}
                  className="cursor-pointer"
                />
              }
            >
              <Typography variant="h7" fontWeight={700} sx={{ py: 3 }}>
                ПРОЕКТЫ
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ px: 6, pb: 6, background: "none" }}>
              <Grid container mt={1} spacing={6}>
                {projectWidgets.map((prj) => {
                  return (
                    <Grid
                      key={prj.widgetName}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      xl={4}
                    >
                      <Widget
                        title={prj.widgetName}
                        href={`/projects/${prj.widgetConstructionComplexId}`}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </HomePageInstrumentsWrapper>
    </>
  );
};
