import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import sbm_avatar from "../../../../../../assets/images/avatars/male_avatar.png";

export const CorrespondentAvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  ${FLEX_ROW_FULL};
  width: 40px;
  height: 40px;
`;

export const ActivityIndicator = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.success.main};
  margin: 28px 0 0 32px;
  z-index: 1000;
`;

export const AvatarWrapper = styled.div<{
  $fromSystem?: boolean;
  $url?: string;
}>`
  border-radius: 50%;
  background-image: ${({ $fromSystem, $url }) =>
    $url ? `url(${$url})` : $fromSystem ? `url(${sbm_avatar})` : ``};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const AvatarInitialsWrapper = styled.div<{ $isReadMessage: boolean }>`
  border-radius: 50%;
  ${FLEX_ROW_FULL};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 40px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-width: ${({ theme, $isReadMessage }) =>
    $isReadMessage ? "1px" : theme.spacing()};
  border-style: solid;
  cursor: pointer;
  z-index: 1;
`;
