import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

export const ContentWrapper = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;
