import React from "react";
import { useTranslation } from "react-i18next";

import { fetchLinkToFile, useGetCorrespondenceFilesById } from "api";
import { closeDrawer, UI_CONFIG, useAppDispatch, useAppSelector } from "app";
import { DotsLoader, Drawer } from "ui-kit";

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { MS_OFFICE_MIME_TYPES, PREVIEW_MIME_TYPES } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { DrawerTypes, DrawerVariantsEnum, MimeTypesEnum } from "@types";

import { getFileStatus } from "./helpers";
import { ContentWrapper } from "./styles";

interface FilePreviewDrawerProps {
  correspondenceId: number;
}

export const FilePreviewDrawer: React.FC<FilePreviewDrawerProps> = ({
  correspondenceId,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("correspondence");
  const { t: tAttachments } = useTranslation("attachments");

  const { drawer } = useAppSelector((state) => state.global);

  const [selectedFileId, setSelectedFileId] = React.useState<
    string | undefined
  >(undefined);

  const [link, setLink] = React.useState<string | undefined>();

  const { data, isLoading } = useGetCorrespondenceFilesById(correspondenceId);

  const selectedFile = data?.files.find(
    (file) => file.id === Number(selectedFileId)
  );

  const handleCancel = () => {
    dispatch(closeDrawer());
  };

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedFileId(value);
  };

  React.useEffect(() => {
    if (selectedFile) {
      const getLinkToFile = async () => {
        let linkToFile = "";

        if (
          MS_OFFICE_MIME_TYPES.includes(
            selectedFile.fileFormat as MimeTypesEnum
          ) &&
          selectedFile.convertedPdfFileName
        ) {
          linkToFile = await fetchLinkToFile(selectedFile.convertedPdfFileName);
        } else if (
          PREVIEW_MIME_TYPES.includes(selectedFile.fileFormat as MimeTypesEnum)
        ) {
          linkToFile = await fetchLinkToFile(selectedFile.fileNameForSystem);
        }

        return linkToFile;
      };

      getLinkToFile()
        .then((link) => {
          setLink(link);
        })
        .catch(() => setLink(undefined));
    }
  }, [selectedFile]);

  React.useEffect(() => {
    if (!selectedFileId) {
      if (data?.files.length) {
        setSelectedFileId(data.files[0].id.toString());
      }
    }
  }, [data, selectedFileId]);

  if (drawer !== DrawerVariantsEnum.filePreview) return null;
  if (isLoading) return <DotsLoader />;

  return (
    <Drawer
      anchor="right"
      open={Boolean(drawer)}
      onClose={handleCancel}
      resizable={false}
      width={UI_CONFIG.rightDrawerFullWidth}
      type={DrawerTypes.createLetterDrawer}
      title={tAttachments("preview_documents")}
      actions={
        <Stack display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
        </Stack>
      }
    >
      <ContentWrapper>
        <Typography variant="body1" color="text.secondary">
          {tAttachments("upload.select.file")}
        </Typography>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={selectedFileId}
          sx={{ flexDirection: "row", ml: 4 }}
          onChange={handleChange}
        >
          {data?.files.map((file) => (
            <Tooltip
              key={file.id}
              arrow
              placement="top"
              title={
                <Stack py={2}>
                  <Typography variant="body1" sx={{ lineHeight: "18px" }}>
                    {file.fileNameForUsers}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                    {tAttachments(
                      `correspondence_type_of_attachments.${file.typeOfAttachment}`
                    )}
                  </Typography>
                  <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                    {tAttachments(
                      tAttachments(getFileStatus(file.fileStatus!))
                    )}
                  </Typography>
                </Stack>
              }
            >
              <FormControlLabel
                value={file.id}
                control={<Radio color="secondary" />}
                sx={{ alignItems: "flex-end" }}
                label={
                  <Stack>
                    <Typography
                      variant="body1"
                      className="ellipsis"
                      sx={{ lineHeight: "18px", maxWidth: 200 }}
                    >
                      {file.fileNameForUsers}
                    </Typography>
                    <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                      {tAttachments(
                        `correspondence_type_of_attachments.${file.typeOfAttachment}`
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ lineHeight: "14px" }}>
                      {tAttachments(
                        tAttachments(getFileStatus(file.fileStatus!))
                      )}
                    </Typography>
                  </Stack>
                }
              />
            </Tooltip>
          ))}
        </RadioGroup>

        {link ? (
          <iframe
            src={link.concat("#toolbar=0&navpanes=0&view=fitH&statusbar=0")}
            title={link}
            width="100%"
            height="100%"
          />
        ) : (
          <Typography sx={{ mt: 2 }} fontWeight={700}>
            {tAttachments("preview_not_available")}
          </Typography>
        )}
      </ContentWrapper>
    </Drawer>
  );
};
