import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "app";
import { Icon } from "ui-kit";

import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { TaskCategoryEnum } from "@types";

import { TasksFieldsEnum } from "../../constants";

const DEFAULT_CATEGORIES = Object.values(TaskCategoryEnum);

export const CalendarFilters = () => {
  const { t } = useTranslation("tasks");
  const { palette } = useTheme();
  const { remove, append, set } = useQueryParams();

  const [active, setActive] = useState(false);
  const [categories, setCategories] = useState(DEFAULT_CATEGORIES);

  const handleSelect = (
    _: React.MouseEvent<HTMLElement>,
    values: TaskCategoryEnum[]
  ) => {
    setCategories(values);

    if (values.length > 0) {
      remove("task_category");
      values.forEach((item) => {
        append("task_category", item);
      });
    } else {
      set("task_category", "null");
    }
  };

  const handleClickOverdue = () => {
    setActive((prev) => {
      const newValue = !prev;

      if (newValue) {
        set(TasksFieldsEnum.includeOverdue, JSON.stringify(newValue));
      } else {
        remove(TasksFieldsEnum.includeOverdue);
      }
      return newValue;
    });
  };

  const CategoryButtonMap = useMemo(
    () => ({
      [TaskCategoryEnum.requiresAction]: {
        bgColor: palette.secondary.main,
      },
      [TaskCategoryEnum.inTheSpotlight]: {
        bgColor: "#6173A0",
      },
      [TaskCategoryEnum.completed]: {
        bgColor: palette.other.grey,
      },
    }),
    [palette.secondary.main, palette.other.grey]
  );

  return (
    <Stack flexDirection="row" alignItems="center" mr={4} gap={2}>
      <ToggleButtonGroup
        color="secondary"
        size="medium"
        value={categories}
        onChange={handleSelect}
      >
        {DEFAULT_CATEGORIES.map((category) => {
          return (
            <ToggleButton key={category} value={category}>
              <Tooltip
                arrow
                placement="top"
                title={t(`categories.${category}`)}
              >
                <Stack
                  width={25}
                  height={12}
                  border="1px solid"
                  borderRadius={1}
                  borderColor={CategoryButtonMap[category].bgColor}
                  bgcolor={
                    categories.includes(category)
                      ? CategoryButtonMap[category].bgColor
                      : "transparent"
                  }
                />
              </Tooltip>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <ToggleButton
        color="secondary"
        value="overdue"
        size="medium"
        selected={active}
        onClick={handleClickOverdue}
        sx={{ p: 1.5 }}
      >
        <Icon
          name="AlarmClock"
          color={hexToRgba(palette.primary.main, 0.5)}
          className="cursor-pointer"
        />
      </ToggleButton>
    </Stack>
  );
};
