import { hexToRgba } from "../ui";
import { Theme } from "@mui/material";

export enum ContractFieldsEnum {
  contractCategoryId = "contractCategoryId",
  contractTypeId = "contractTypeId",
  contractSubtypeId = "contractSubtypeId",
  dateContract = "dateContract",
  constructionComplexId = "constructionComplexId",
  dateValidityExpiryContract = "dateValidityExpiryContract",
  party1OrganizationId = "party1OrganizationId",
  party2OrganizationId = "party2OrganizationId",
  party3OrganizationId = "party3OrganizationId",
  party1RoleId = "party1RoleId",
  party2RoleId = "party2RoleId",
  dateOfSigning = "dateOfSigning",
  expirationDate = "expirationDate",
  statusContract = "contractStatus",
  responsibleForPreparationContractId = "responsibleForPreparationContractId",
}

export const ContractFieldNamesEnum = {
  category: "category",
  type: "type",
  subtype: "subtype",
  date: "date.of.signing",
  expiryDate: "expiration.date",
  dateOfSigning: "date.of.signing",
  constructionComplex: "construction.complex",
  party1Organization: ["party", "1 -", "organization"],
  party2Organization: ["party", "2 -", "organization"],
  party3Organization: ["party", "3 -", "organization"],
  party1Role: ["party", "1 -", "role"],
  party2Role: ["party", "2 -", "role"],
};

enum ContractStatusEnum {
  draft = "draft",
  preparation = "preparation",
  inAgreement = "in_agreement",
  inAction = "in_action",
  sentToContractDept = "sent_to_contract_dept",
  underSigning = "under_signing",
  rejected = "rejected",
  paused = "paused",
  terminated = "terminated",
  disputed = "disputed",
  canceled = "canceled",
  executed = "executed",
}

export const getContractStatusColor = (contractStatus?: ContractStatusEnum) => {
  switch (contractStatus) {
    case ContractStatusEnum.inAgreement:
      return "warning";
    case ContractStatusEnum.inAction:
      return "success";
    case ContractStatusEnum.underSigning:
    case ContractStatusEnum.preparation:
      return "secondary";
    default:
      return "info";
  }
};

// TODO: Remove after implementation of colors from backend
export const getCCStatusColor = (contractStatus: ContractStatusEnum, theme: Theme) => {
  switch (contractStatus) {
    case ContractStatusEnum.draft:
      return {
        bgColor: theme.palette.secondary.main,
        textColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.secondary.main,
      };
    case ContractStatusEnum.preparation:
      return {
        bgColor: theme.palette.secondary.main,
        textColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.secondary.main,
      };
    case ContractStatusEnum.inAgreement:
      return {
        bgColor: theme.palette.secondary.main,
        textColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.secondary.main,
      };
    case ContractStatusEnum.inAction:
      return {
        bgColor: hexToRgba("#3A5088", 0.8),
        textColor: theme.palette.primary.contrastText,
        borderColor: hexToRgba("#3A5088", 0.8),
      };
    case ContractStatusEnum.underSigning:
    case ContractStatusEnum.sentToContractDept:
      return {
        bgColor: theme.palette.secondary.main,
        textColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.secondary.main,
      };
    case ContractStatusEnum.terminated:
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor: theme.palette.other.grey,
        textColor: theme.palette.text.secondary,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderColor: theme.palette.other.grey,
      };
    case ContractStatusEnum.disputed:
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor: theme.palette.other.grey,
        textColor: theme.palette.text.secondary,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderColor: theme.palette.other.grey,
      };
    case ContractStatusEnum.canceled:
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor: theme.palette.other.grey,
        textColor: theme.palette.text.secondary,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderColor: theme.palette.other.grey,
      };
    case ContractStatusEnum.executed:
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor: theme.palette.other.grey,
        textColor: theme.palette.text.secondary,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderColor: theme.palette.other.grey,
      };
    default:
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor: theme.palette.other.grey,
        textColor: theme.palette.text.secondary,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        borderColor: theme.palette.other.grey,
      };
  }
};

export const chipStyle = (status: ContractStatusEnum, theme: Theme) => {
  return {
    backgroundColor: getCCStatusColor(status, theme).bgColor,
    color: getCCStatusColor(status, theme).textColor,
    border: `1px solid ${getCCStatusColor(status, theme).borderColor}`,
    fontWeight: 600,
  };
};
