import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";

export const CreateArchiveDocumentNoParticipantWarning = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { t } = useTranslation("project");

  const content = {
    title: t("create_project_empty_participants_text"),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={onClose}
      >
        {t("ok")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
