import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const CardWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(4)} 0`};
  width: 100%;
  height: 230px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: ${({ theme }) => theme.spacing(45)};
  min-height: ${({ $height }) => `${$height}px`};
  max-height: ${({ $height }) => `${$height}px`};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => hexToRgba(theme.palette.primary.contrastText, 0.7)};
  &:hover {
    cursor: pointer;
  }
`;
