import { FileStatusEnum } from "@types";

export const getFileStatus = (status: FileStatusEnum) => {
  switch (status) {
    case FileStatusEnum.signed:
      return "document.signed";
    case FileStatusEnum.draft:
      return "document.draft";
    default:
      return "";
  }
};
