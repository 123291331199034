import {
  FileAttachedToGanttChartFileStatusEnum,
  GanttChartTypeOfAttachmentEnum,
} from "@types";

export interface IAttachmentForm {
  typeOfAttachment?: GanttChartTypeOfAttachmentEnum;
  fileStatusAttachment?: FileAttachedToGanttChartFileStatusEnum;
  file: null | File;
}

export const ATTACHMENT_DEFAULT_VALUES: IAttachmentForm = {
  typeOfAttachment: undefined,
  fileStatusAttachment: undefined,
  file: null,
};
