import { UI_CONFIG } from "app";
import styled from "styled-components";
import {
  FLEX_BETWEEN,
  FLEX_COLUMN,
  FLEX_COLUMN_FULL,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
  FLEX_ROW_FULL,
} from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

import male_avatar from "../../../../assets/images/avatars/male_avatar.png";

export const Wrapper = styled.div`
  height: 100%;
  ${FLEX_COLUMN};
  justify-content: space-between;
  .empty_text {
    padding: ${({ theme }) => theme.spacing(2)};
    ${FLEX_ROW_FULL}
  }
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  ${FLEX_ROW};
  align-items: end;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(6, 4)};

  button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
`;
export const TitleWrapper = styled.div`
  ${FLEX_ROW};
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const DrawerTitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
`;
export const ContentWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 0, 2, 0)};
  .correspondent__avatar {
    ${FLEX_ROW_FULL};
    width: 50px;
    height: 50px;
    color: white;
    background-color: black;
    border-radius: 50%;
  }
  .message__content {
    max-height: 160px;
    overflow: auto;
  }
`;
export const CorrespondentAvatarWrapper = styled.div<{
  $fromSystem?: boolean;
  $hasAvatar?: boolean;
}>`
  ${FLEX_ROW_FULL};
  width: 40px;
  height: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const AvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  border-radius: 50%;
  background-image: ${({ $fromSystem }) =>
    $fromSystem ? `url(${male_avatar})` : ``};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`;

export const AttachedFilesWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0, 0, 0)};

  .attached__files {
    ${FLEX_COLUMN};
    max-height: 70px;
    overflow: auto;
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
export const AttachedFile = styled.div`
  ${FLEX_BETWEEN};
  width: 100%;
  height: 32px;
  padding: ${({ theme }) => theme.spacing(2, 1.5)};

  .attached_file_item {
    ${FLEX_ROW_FULL};
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.primary.light, 0.04)};
    }
  }
`;

export const TabsWrapper = styled.div`
  ${FLEX_ROW_FULL};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};

  .attachment_tab {
    ${FLEX_ROW_FULL};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    width: 100%;
    cursor: pointer;
  }
`;

export const ActivityIndicator = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.success.main};
  margin: 28px 0 0 32px;
`;

export const AvatarInitialsWrapper = styled.div`
  border-radius: 50%;
  ${FLEX_ROW_FULL};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 40px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-width: 1px;
  border-style: solid;
`;
