import {
  ContractStatusEnum,
  CustomFile,
  IAttachLink,
  ILink,
  ILinkedEntity,
} from "@types";

import { StatusForCorrespondenceEnum } from "./correspondence";

export interface IProjectById {
  fullTextConstructionComplexName: string;
  id: number;
}

export enum StandartParameterNameEnum {
  senderOrganization = "sender_organization",
  recipientOrganization = "recipient_organization",
  fullNameOfTheSigner = "full_name_of_the_signer",
  recipientName = "recipient_name",
}

export enum StandartTypeOfTheDocumentEnum {
  contract = "contract",
  letter = "letter",
  order = "order",
  serviceNote = "service_note",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
}

export interface IProjectProjectParticipants {
  id: number;
  briefTextOrganizationName: string;
  roleForParticipant: RoleForParticipantEnum;
  roleNames: string[];
  shortName: string;
  organizationId?: number;
}

export interface IProjectStakeholder {
  participantOfProjectStakeholderId: number;
}
export interface IShareDocument {
  documentId: number;
  participantOfProjectStakeholderId: number;
  constructionComplexId: number;
}

export interface IProjectParameter {
  valueOfParameter: string;
  standartParameterName: StandartParameterNameEnum; // 200 symbol
  customParameterName: string; // 1000 symbol
}

export enum RandomDocumentTypeOfAttachmentEnum {
  mainDocument = "main_document",
  appendix = "appendix",
  deliveryConfirmation = "delivery_confirmation",
  supportingFile = "supporting_file",
}

export interface IProjectForm {
  parameters: IProjectParameter[];
  links?: IAttachLink[] | number[];
  files?: CustomFile[];
  fileIds?: number[];
  typeOfAttachment: RandomDocumentTypeOfAttachmentEnum[];
  constructionComplexId: number | string;
  organizationId?: number;
  participantOfProjectStakeholderId?: string | number;
  documentName: string;
  context?: string;
  selectedParameters?: {
    index?: number;
    name?: string;
    value?: string;
  }[];
  standartTypeOfTheDocument?: StandartTypeOfTheDocumentEnum;
  customTypeOfTheDocument?: string;
  customParameter?: string;
  fileAttachments?: {
    fileId: number;
    typeOfAttachment: RandomDocumentTypeOfAttachmentEnum;
  }[];
}

export enum GanttChartTypeOfAttachmentEnum {
  main_document = "main_document",
  appendix = "appendix",
  supporting_file = "supporting_file",
}

export enum FileAttachedToGanttChartFileStatusEnum {
  draftDocument = "draft_document",
  signedDocument = "signed_document",
}

export enum TypeOfDocumentEnum {
  contract = "contract",
  incomingDocument = "incoming_document",
  outgoingDocument = "outgoing_document",
  serviceNote = "service_note",
  randomArchiveDocument = "random_archive_document",
  supplementaryAgreement = "supplementary_agreement",
  oad = "oad",
  workplan = "workplan",
  minutesInternalMeeting = "minutes_internal_meeting",
  message = "message",
  task = "task",
  regularTask = "regular_task",
  externalLink = "external_link",
  letter = "letter",
  order = "order",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
}

export interface Rubric {
  rubricId: number;
  nameOfTheRubric: string;
}

export interface IProjectArchive {
  documentId?: number;
  constructionComplexId?: number;
  contractId?: number;
  randomArchiveDocumentId?: number;
  correspondenceId?: number;
  longNameOfTheDocument?: string;
  outerNameOfTheDocument?: string;
  status?: ContractStatusEnum | StatusForCorrespondenceEnum;
  typeOfTheDocument?: TypeOfDocumentEnum;
  registrationNumber: string;
  registrationDate: Date;
  contextOfTheDocument: string;
  createdAt: Date;
  rubrics?: Rubric[];
  participantShortName?: string;
  participantBriefTextOrganizationName?: string;
}

export enum RoleNameProjectStakeholderEnum {
  investor = "investor",
  customer = "customer",
  technicalCustomer = "technical_customer",
  generalDesigner = "general_designer",
  subdesigner = "subdesigner",
  generalContractor = "general_contractor",
  subcontractor = "subcontractor",
  supplier = "supplier",
  supervisoryAgency = "supervisory_agency",
  prospector = "prospector",
  externalExpert = "external_expert",
}

export enum RoleForParticipantEnum {
  projectManager = "project_manager",
  constructionSupervision = "construction_supervision",
  technologicalSupport = "technological_support",
  pricing = "pricing",
  supply = "supply",
  accountingSupport = "accounting_support",
  chiefProjectEngineer = "chief_project_engineer",
  design = "design",
  projectImplementationControl = "project_implementation_control",
}

export interface IParticipant {
  id: number;
  longName: string;
  roleForParticipant: RoleForParticipantEnum[];
  chiefProjectManager: boolean;
  parentId: number | null;
  personalDataId: number;
  shortName: string;
}

export enum FunctionalDirectionEnum {
  projectManagement = "project_management",
  design = "design",
  construction = "construction",
  supply = "supply",
}

export interface IRoles {
  id: number;
  roleNameProjectStakeholder: RoleNameProjectStakeholderEnum;
  functionalDirection: FunctionalDirectionEnum;
  description: string;
}

export interface IProjectStructure {
  id: number;
  roles: IRoles[];
  participants: IParticipant[];
  organizationId: number;
  organizationName: string;
  parentId: number | null;
}

export enum StandartTypeOfGanttChartEnum {
  projectImplementationSchedule = "project_implementation_schedule",
  workSchedule = "work_schedule",
  designSchedule = "design_schedule",
  deliverySchedule = "delivery_schedule",
}

export type ICreateChart = {
  ganttChartName: string;
  description?: string;
  participantOfProjectStakeholderId: number | string | undefined;
  typeOfGanttChart?: string | undefined;
  links?: ILink[] | number[];
  files?: CustomFile[];
  fileIds?: number[];
  typeOfAttachment?: GanttChartTypeOfAttachmentEnum[] | undefined;
  ganttChartPlannedStartDate: Date | string;
  ganttChartPlannedCompletionDate: Date | string;
  standartTypeOfGanttChart?: ParameterForRandomArchiveDocumentEnum | string;
  customTypeOfGanttChart?: string;
  fileAttachments?: {
    fileId: number;
    typeOfAttachment: GanttChartTypeOfAttachmentEnum;
  }[];
};

export interface IParameters {
  name: string;
  value: string;
}

export enum ParameterForRandomArchiveDocumentEnum {
  senderOrganization = "sender_organization",
  recipientOrganization = "recipient_organization",
  fullNameOfTheSigner = "full_name_of_the_signer",
  recipientName = "recipient_name",
}

export interface IRandomArchiveDocument {
  id: number;
  context: string;
  creationDate: string;
  documentName: string;
  documentType: StandartTypeOfTheDocumentEnum;
  isClassifiedDocument: boolean;
  parameters: IParameters[];
  registeredBy: string;
  registrationNumber: string;
}

export enum FileAttachedToRandomDocumentTypeOfAttachmentEnum {
  mainDocument = "main_document",
  appendix = "appendix",
  deliveryConfirmation = "delivery_confirmation",
  supporting_file = "supporting_file",
}

export interface IFileAttachments {
  fileId: number;
  typeOfAttachment: FileAttachedToRandomDocumentTypeOfAttachmentEnum;
  fileName?: string;
}

export enum LinkAttachedToRandomDocumentTypeOfAttachmentEnum {
  auxiliaryDocument = "auxiliary_document",
}

export interface ILinkAttachments {
  links: number[];
  linkAssignment?: LinkAttachedToRandomDocumentTypeOfAttachmentEnum;
  descriptionForAttachment?: string;
}

export interface IProjectParticipant {
  briefTextOrganizationName: string;
  id: number;
  organizationId: number;
  roleForParticipant: RoleForParticipantEnum;
  roleNames: RoleNameProjectStakeholderEnum[];
  shortName: string;
}

export enum StatusOfGanttChartEnum {
  pending = "pending",
  performance = "performance",
  completed = "completed",
}

export interface IGanttChart {
  actualCompletionDate: Date | string | null;
  actualStartDate: Date | string | null;
  chartType: StandartTypeOfGanttChartEnum | string;
  description: string;
  id: number;
  name: string;
  organizationName: string;
  plannedCompletionDate: Date | string | null;
  plannedStartDate: Date | string | null;
  registartionDate: Date | string | null;
  registrationNumber: string;
  status: StatusOfGanttChartEnum;
}

interface IFileAttachedBy {
  id: number;
  longName: string;
  personalDataId: number;
  shortName: string;
  userId: number;
}

interface IFiles {
  id: number;
  typeOfAttachment: FileAttachedToRandomDocumentTypeOfAttachmentEnum;
  fileAttachedBy: IFileAttachedBy;
  additionalInformation: string | null;
  fileFormat: string;
  fileNameForSystem: string;
  fileNameForUsers: string;
  fileUploadDate: string;
}

type TEmployee = Omit<IFileAttachedBy, "userId">;

interface ILinks {
  id: number;
  createdAt: string;
  descriptionForAttachment: string;
  employee: TEmployee;
  linkedEntities: ILinkedEntity;
  shortNameOfTheDocument: string;
  typeOfAttachment: LinkAttachedToRandomDocumentTypeOfAttachmentEnum;
}

export interface IRandomDocumentFiles {
  id: number;
  files: IFiles[];
}

export interface IRandomDocumentLinks {
  id: number;
  links: ILinks[];
}
